@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow() {
  -webkit-box-shadow: 2px 3px 10px 3px rgba(0,0,0,0.43);
  -moz-box-shadow: 2px 3px 10px 3px rgba(0,0,0,0.43);
  box-shadow: 2px 3px 10px 3px rgba(0,0,0,0.43);
}

@mixin non-selectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
