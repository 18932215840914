.user-search {

  form{
    display: inline;
  }

  .glyphicon-search {
    font-size: 1.15em;
  }

  .search-bar {
    margin-left: 3px;
  }

  .btn {
    margin-bottom: 5px;
    margin-left: 10px;
  }
}