.popup-container {
  width:100%;height:100%;
  top:0; left:0;
  z-index: 10; position:absolute;
  background-color: rgba(0,0,0,0.1);
  text-align: center;

  .popup-content {
    text-align: left;
    margin: 40px 0 0 0;
    background-color: rgba(255, 255, 255, 0.95);
    display: inline-block;
    position: relative;
    padding: 5px;
    min-width: 20px;
    min-height: 20px;
    border: solid 1px #CCCCCC;
    @include border-radius(5px);
    @include box-shadow();

    .error-msg {
      padding: 5px;
    }

  }
}

.json-viewer {

}