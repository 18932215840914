.resource-edit {

  input[type='text'], input[type='password'], input[type='email'] {
    width: 300px;

  }

  .error {
    color: #ba0000;
  }

  .fields-cont {
    margin: 0 0 10px 0;

    > div {
      position: relative;
      margin-bottom: 5px;
    }

    .btn-upload-thumb {
      background-color: #EEEEEE;
      border: solid 1px #BBBBBB;
      color: #555555;
      width: 100%;
      height: 60px;
      top:0;left:0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

  }

  .btn-cont {

    button { float: right; margin-left: 5px; }

  }

  .small-text {
    font-size: 70%;
    width:300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // Template resource specific styles
  &.template-resource,
  &.utility_layout-resource,
  &.story_layout-resource,
  &.custom_layout-resource {
    padding: 10px;
    width: 800px;
    height: 100vh;
    overflow: scroll;

    label {}
    margin-bottom: 10px;

    label, input {
      display: block;
    }

    #description {
      padding: 5px;
      width: 100%;
      height: 70px;
    }

    .re-thumbnail-container {
      display: inline-block;
      width: 49%;
      padding: 3px 5px;

      .btn-upload-thumb {
        width: 100% !important;
        height: 228px !important;
      }
    }

    .made-with-thumb {
      display: inline-block;
      width: 48%;
      height: 200px;
      vertical-align: top;
      margin: 5px;
      padding: 10px;
      border: 1px dashed black;

      .made-with-thumb-edit {
        height: 30px;
        margin: 5px 0 7px;

        input {
          display: inline-block;
          width: 80%;
          height: 30px;
        }

        button {
          width: 19%;
        }
      }

      .made-with-thumb-preview {
        height: 140px;
        position: relative;
        padding: 5px;
        @include border-radius(5px);
        overflow: hidden;

        .made-with-thumb-title {
          color: white;
          z-index: 1;
          position: relative;
          max-width: 320px;
        }
        .made-with-thumb-remove {
          position: absolute;
          right: 2px;
          top: 2px;
          border-radius: 50%;
          background: white;
          width: 24px;
          height: 24px;
          line-height: 24px;
          z-index: 1;
          font-size: 12px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  &.utility_layout-resource,
  &.story_layout-resource {

    .select {
      display: block;
      width: fit-content;
    }
    select {
      width: 300px;
    }

    .notification {
      width: 300px;
      margin: 20px 0;
    }

    #setAsSlideshowLayout {
      display: inline-block;
      margin-left: 5px;
    }

    .expand {
      width: 300px;
      color: gray;
      padding: 0 5px;
      height: 25px;
      border: none;
      border-radius: 5px;
      box-shadow: 0 0 2px #3a3a3a;
    }
  }
}
