.images-list {

}

.image-cont {
  position: relative;
  display: inline-block;
  width:160px;
  height: 100px;
  border: solid 1px #DEDEDE;
  margin: 5px 0 0 5px;
  @include border-radius(5px);

  .resource-elements {
    color: white;
    position: relative; 
    width: 100%; 
    height: 100%;
    .resource-element {
      position: absolute;
      background: rgba(0, 0, 0, 0.4);
      text-align: center;
      border: 1px solid black;
      .resource-element-text {
        position: relative;
        font-size: 12px;
        width: 100%;
        top: 50%;
      }
    }
  }

  .image-bg {
    width:100%; height:100%;
    position: absolute;
    background-color: #FFFFFF;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include border-radius(5px);
  }

  .image-svg {
    background-size: contain;
  }

  .overlay {
    position: absolute; width:100%; height:100%;
    @include border-radius(5px);
  }

  .image-cont-elems {
    width:100%; height: 100%;
    overflow: hidden;
  }

  &.selected {
    border: solid 1px #222222;
    .image-cont-elems {
      opacity: 0.2 !important;
    }
    .res-name {
      opacity: 1;
    }
  }

  .error {
    top:0; left:0;
    width:100%; height:100%; position:absolute;
    background-color: rgba(200,0,0,0.7);
    pointer-events: none;
    @include border-radius(5px);

    div {
      line-height: 16px;
      font-size: 13px;
      margin: 3px;
      color: white;
    }
  }

  .controls {
    display: none;
    position: absolute; top:0; right:0;

    div {
      position: relative;
      margin: 1px 1px 0 1px;
    }

    .glyphicon {
      @include border-radius(3px);
      background-color: rgba(255,255,255,0.4);
      padding: 3px;
    }

    

  }

  .marker-layer {
    top:0; right:0;
    width: 100%;
    height: 100%;
    position: absolute;

    .glyphicon-ok {
      position: absolute;
      top: -1px;
      left: -5px;
      font-size: 22px;
      color: #53bb00;
      text-shadow:
      3px 3px 0 #000,
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;    }
  }

  .res-name {
    font-size: 80%;
    font-weight: bold;
    @include border-radius(3px);
    background-color: rgba(255,255,255, 0.4);
    padding: 2px;
    opacity: 0;
    position: absolute;
    top:1px; left:1px;
  }

  .text {
    padding: 3px 5px;
  }

}


.image-cont:hover {
  border: solid 1px #222222;

  .res-name {
    opacity: 1;
  }

  .image-cont-elems {
    opacity: 0.7;
  }


  .controls {
    display: block;
  }

  &.selected {
    border: solid 1px #222222;
    .image-cont-elems {
      opacity: 0.2;
    }
  }
}
