.users-wrapper {
  margin-top: 30px;
  display: flex;
}


.table-wrapper {
  margin-right: 40px;
}

.user-search {
  position: relative;
  margin-bottom: 30px;

  #user-search {
    width: 100%;
    height: 50px;
    padding-left: 20px;
    box-shadow: 0 0 10px #b6b6b6;
    border-radius: 3px;
  }

  .glyphicon {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

.users-table {
  border-collapse: collapse;

  thead {
    tr {
      background: black;

      th {
        color: white;
        padding: 20px;

        &:not(:last-child) {
          border-right: 1px solid #323132;
        }
      }

      .role-column {
        width: 28%
      }

      th:first-child {
        border-top-left-radius: 5px;
      }

      th:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  tbody {
    tr {
      background: white;

      &:hover {
        background: #f5f5f5;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #f1f1f1;
      }

      td {
        padding: 15px;

        &:not(:last-child) {
          border-right: 1px solid #f1f1f1;
        }
      }

      .user-image {
        position: relative;
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }

      .role-cell {
        display: flex;
        text-transform: uppercase;

        .role-circle {
          margin: 5px 5px 0 0;
        }

        md-select {
          margin: 0;
        }
      }

      .update-cell {
        padding: 0;
        vertical-align: middle;
        text-align: center;

        &:hover {
          .update-text {
            opacity: 1;
            transform: none;
          }

          .glyphicon {
            transform: none;
          }
        }

        .glyphicon {
          display: block;
          transition: .5s;
          transform: translateY(9px);
        }

        .update-text {
          font-size: 12px;
          margin: 0;
          transition: .5s;
          transform: translateY(17px);
          opacity: 0;
        }
      }
    }
  }
}

.role-option {
  text-transform: uppercase;
}

.role-circle {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  &.admin {
    background: #31db90;
  }

  &.editor-role
  {
    background: #ee9802;
  }

  &.viewer {
    background: #fecb2f;
  }

  &.none {
    background: #b7b7b7;
  }
}

.users-legend {
  width: 100%;

  .role-wrapper {
    border-bottom: 1px solid #a7a7a7;
    margin-right: 25%;
    margin-bottom: 20px;
    padding-bottom: 15px;

    .role-circle {
      vertical-align: top;
      margin: 7px 5px 0 0;
    }

    .role-text-wrapper {
      display: inline-block;

      h4 {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

