*:focus {
  outline: none;
}

body,
a,
button,
input,
select,
textarea {
  font-family: 'Lato', "Meoded Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
}


html {
  height: 100%; width:100%;
}

body {
  background: $soft-grey;
  height: 100%; width:100%;
}

body > div {
  //height: 100%;
  position: relative;
}