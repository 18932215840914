.btn-download {
  width: 132px;
}

.internal-reports {

  select, input[type='text'], input[type='password'], input[type='email'] {
    height: 33px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: none;
  }

  .top-gutter-30 {
    margin-top: 30px;
  }

  .alert {
    opacity: 0;
    &.visible {
      transition: 1s opacity ease-out;
      opacity: 1;
    }
  }
}