.container {
  width: 70%;
}

input[type="text"]:not(.input), input[type='password'], input[type='email'], input[type='number']:not(.input) {
  padding: 0 5px;
  height: 25px;
  border: none;
  @include border-radius(5px);
  box-shadow: 0 0 2px #3a3a3a;

  &.ng-invalid.ng-touched:not(.search-bar) {
    box-shadow: 0 0 5px red;
  }
}
textarea {
  border: none;
  @include border-radius(5px);
  box-shadow: 0 0 2px #3a3a3a;

  &.ng-invalid.ng-touched:not(.search-bar) {
    box-shadow: 0 0 5px red;
  }
}

.btn:focus, .btn:active, .btn:active:focus {
  outline: none;
}

::-webkit-input-placeholder {
  font-size: 17px;
}

.update-message {
  margin-left: 10px;
  font-size: 23px;
  font-weight: 500;
  color: #FF2A2A;
  position: absolute;

  &.success {
    color: #347D34;
  }
}

.update-user {
  display: inline;

  .user-email, .user-name {
    display: inline;
    width: 25%;
  }

  .user-input {
    background: transparent;
    color: #000;
    width: 297px;

    &:focus {
      background: white;
    }
  }

  .glyphicon-pencil {
    margin-right: 74px;
    margin-left: 5px;
    cursor: pointer;
  }

  .update {
    margin-right: 15px;
  }
}

.extend {
  min-width: 60px;
}

.user-details {
  margin-left: 0;

  label {
    font-size: 17px;
  }

  span {
    font-size: 15px;
  }

  .toggle {
    margin-left: 5px;
  }
}

.separator {
  height: 1.5px;
  background-color: #c4c4c4;
}


.general {
  position: relative;
  height: 100%;
}

.version {
  position: fixed;
  bottom: 5px; right:5px;
  font-size: 70%; font-weight: bold;
  opacity: 0.5;
  .v-num {
    text-align: right;
  }
  .v-time {
    font-weight: normal;
    font-size: 80%;
  }
  &:hover {
    opacity: 1;
  }
}


.pointer {
  cursor: pointer;
}
