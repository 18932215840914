// COLORS
$apester:           #ee2e3d !default;
$white:               white !default;
$black:               black !default;
$transparent:   transparent !default;
$dark-grey:         #252424 !default;
$dark-grey-text:    #231f20 !default;
//$deep-grey:         #7e7f7f !default;
//$medium-grey:       #a8a7a7 !default;
$soft-grey:         #f7f7f7 !default;
$light-grey:        #ebebeb !default;

// ENGINES
$poll:              #ee9802 !default;
$quiz:              #8fd100 !default;
$correct:           #467b61 !default;
$personality:       #d61edd !default;
$video:             #24dfd0 !default;

// OTHERS
//$twitter:           #55acee !default;
//$facebook:          #3b5998 !default;
//$settings:          #1edd8e !default;
//$ape-blue:          #2b74f9 !default;

