.upload-gradient {

  textarea {
    padding: 5px;
  }

  .image-cont {
    box-shadow: 0 0 2px #3a3a3a;
  }
  .image-cont:hover {
    border: solid 1px #DEDEDE;
    .image-bg, .overlay {
      opacity: 1;
    }
  }

  .buttons {
    width:90px;
    vertical-align: top;
    display: inline-block;
    border: solid 0px red;

    button {
      width: 100%;
      margin: 5px 0 5px 5px;
    }
    button:nth-child(2) {
      margin-top:0;
    }
  }

}
