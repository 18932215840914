.interaction {
  nav {
    input {
      font-size: 1.5vw
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  table.slide {
    margin-bottom: 50px;
    border-bottom: 2px black dashed;

    input {
      width: 100%;
    }

    [ng-bind-html] {
      * {
        font-size: 20px !important;
      }
    }

    thead tr:first-child th {
      border-bottom: 0;
    }

    td:nth-child(1),
    td:nth-child(2) {
      width: 200px;
    }

    td:nth-child(3) {
      width: 300px;
    }
  }

  .modal {

    &.update-success {
      header,
      footer .button:not(.is-primary) {
        display: none;
      }
    }
  }
}

@media (max-width: 700px) {
  .interaction {
    nav input {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 700px) {

}