.ddl-cont {
  display: inline-block;
  position: relative;
  @include border-radius(5px);
  box-shadow: 0 0 2px #303030;
  cursor: default;
  @include non-selectable();

  .ddl-selected, .ddl-placeholder {
    position: relative;
    padding: 3px 5px;
    color: #000;

    .ddl-icon {
      color: #000;
      position: absolute;
      right:3px; top:3px;
      width: 16px; height: 20px;

      .top, .bottom {
        text-align: center;
        position: absolute; left:0;
        width: 16px;
        height: 10px;
        line-height: 0;
        font-size: 12px;

        .glyphicon { position: relative; margin: 0; left:0; top:0; }
      }
      .top { top:0; }
      .bottom { bottom:0; }

    }
  }

  .ddl-placeholder {
    color: #AAAAAA;
  }
  
  .ddl-hidden {
    display: none;
  }
}

.ddl-options-cont {
  @include box-shadow();
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  @include border-radius(5px);
  background-color: white;

  -webkit-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.43);
  -moz-box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.43);
  box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.43);


  .ddl-option {
    position: relative;
    border-bottom: solid 1px #EEEEEE;
    padding: 3px 5px;
    color: #666666;
    background-color: #F5F5F5;
    @include non-selectable();
    cursor: default;

    &:hover {
      color: #000000;
      background-color: #FFFFFF;

      .buttons {
        display: block;
      }
      .markers {
        opacity: 0.2;
      }
    }
    
    .buttons {
      display: none;
      position: absolute;
      width:100%;
      height: 100%;
      left:0; top:0;

      .icon-btn {
        opacity: 0.5;
        border: solid 1px #EEEEEE;
        float: right;
        @include border-radius(3px);
        padding: 5px;
        margin: 1px 1px 1px 0;
        font-size: 70%;

        &:hover {
          opacity: 1;
        }
      }
    }

    .markers {
      position: absolute;
      width:100%;
      height: 100%;
      left:0; top:0;

      i.glyphicon-ok {
        position: relative;
        margin: 5px;
        float: right;
        font-size: 16px;
        color: #53bb00;
        text-shadow:
        1px 1px 0 #000,
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
      }
    }

  }
}