// ========== spin animation ================
.spinAnimation {
  -webkit-animation:spin .5s linear infinite;
  -moz-animation:spin .5s linear infinite;
  animation:spin .5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { transform:rotate(360deg); } }


// ========== pulse animation ===============
.pulseAnimation {
  -webkit-animation:pulse .3s linear infinite;
  -moz-animation:pulse .3s linear infinite;
  animation:pulse .3s linear infinite;
}
@-webkit-keyframes pulse {
  0% { -webkit-transform: scale(1.0); }
  50% { -webkit-transform: scale(1.2); }
  100% { -webkit-transform: scale(1.0); }
}
@-moz-keyframes pulse {
  0% { -moz-transform: scale(1.0); }
  50% { -moz-transform: scale(1.2); }
  100% { -moz-transform: scale(1.0); }
}
@keyframes pulse {
  0% { transform: scale(1.0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1.0); }
}

