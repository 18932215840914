.editor {
  position: relative;
  height: 100%;
  padding: 10px;

  .layout {
    $CENTER_WIDTH: 80px;

    position: relative;
    width: 100%;
    height: 100%;
    display: table;
    padding: 0;
    margin: 0;

    .layout-pane {
      vertical-align: top;
      position: relative;
      display: table-cell;
      width: 50%;
      height: 100%;
      padding-right: $CENTER_WIDTH / 2;
    }

    .layout-pane:nth-child(2) {
      padding-right: 0;
      padding-left: $CENTER_WIDTH / 2;
    }

    .layout-middle {
      position: absolute;
      width: $CENTER_WIDTH;
      height: 100%;
      left: 50%;
      margin-left: - $CENTER_WIDTH / 2;
    }
  }

  button {
    outline: 0 !important;
  }

  .content {
    position: relative;
    background-color: #FFFFFF;
    height: 100%;
    border: solid 1px #DADADA;
    @include border-radius(5px);

    .table {
      display: table;height: 100%;width:100%;
    }
    .table-row {
      display: table-row;
    }
    .table-cell {
      position: relative;display: table-cell;
    }
    .table-row:nth-child(2) > .table-cell {
      height: 100%;
    }
    .list-wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  .btn-right-icon {
    cursor: pointer;
    margin: 150px auto;
    display: block;
    line-height: 55px;
    width:55px; height:55px;
    font-size: 25px;
    text-align: center;
    background-color: #DDDDDD;
    border: solid 1px #AAAAAA;
    @include border-radius(50%);
  }

  .btn-right-icon:hover {
    background-color: #D0D0D0;
    border: solid 1px #A0A0A0;
  }

  .action-bar {
    position: relative;
    width:100%;
    padding: 5px;
    border-bottom: solid 1px #DADADA;

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .glyphicon {
      opacity: 0.6;
      position: absolute;
      right:5px; top:5px;
    }
  }

  .input-wrapper {
    position: relative;
  }
}

.category-editor {
  .inp-size {
    margin-right: 5px;
    height: 28px;
    width:180px;
  }
  .inp-size:last-child {
    margin: 0;
  }
}