.editor-filter {
  border-bottom: solid 1px #DADADA;
  padding:5px 0 0 5px;

  select { margin: 0; }

  input, select { width:180px; height:27px; }
  button { margin: 0 5px 5px 0; }

  * {
    margin: 0 5px 0 0;
  }

}
