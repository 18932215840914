.resource-thumb {

  position: absolute; top:0; left:0;
  width:100%; height: 100%;

  .thumb {
    border: solid 1px #AAAAAA;
    @include border-radius(50%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width:77px; height: 77px;
    top:50%; left:50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}

.resource-text {
  max-width: 100%;
  max-height: 100%;
}
